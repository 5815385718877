


import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";


// Importing child components
import Signature from "@/views/DigitalSignature.vue";
import DisclosuresList from "@/views/disclosures/DisclosuresList.vue";
import _ from "lodash";
import DisplayFiles from "@/views/DisplayDocument.vue";
import PDFViewer from "@/components/pdf/PDFViewer.vue";
import SigneeValidation from "@/components/disclosures-package/signee-validation/SigneeValidation.vue";
import SaveInformationMixin from "@/mixins/SaveInformationMixin";
import { mixins } from "vue-class-component";
import LOSMixin from "@/mixins/los.mixin";

const sleep = m => new Promise(r => setTimeout(r, m));

@Component({
  components: {
    DisclosuresList,
    Signature,
    DisplayFiles,
    PDFViewer,
    SigneeValidation,
  },
})
export default class ChooseDisclosures extends mixins(LOSMixin, SaveInformationMixin) {
  @Prop()	public sourceType;
  @Prop()	public borrowerInfo;
  @Prop({ default: false })	public isLoanSubmission;
  @Prop() public loanIsLocked;

  private userId: String = null;
  private loanTxnId: String = null;
  private renderCase: String = null;
  private sendNowOrLater: string = "sendLater";
  private loanType = null;
  private activeTabId: string = 'draw';

  public digitalSignature = "";
  public uploadedSignature: string = "";
  public typedSignature: string = "";
  public selectedPdf: any = {};
  public originalDisclosures = [];
  public originalAdditionalDisclosures = [];
  public originalConditionalDisclosures = [];
  public disclosures = [];
  public additionalDisclosures = [];
  public showDisclosuresList = false;
  public visibleDisclosure: number = null;
  public sendingNoneConfirmation: boolean = false;
  public pendingSignatures: any = [];
  public loadingDisclosuresPage: boolean = true;
  public textSignature2DContext: any;
  public disclosureRefusal: boolean = false;
  public isDisclosuresDeclined: boolean = false;
  public isDiclineToSend: boolean = false;
  public signature: string = null;

  /**
   * Signee validation properties
   */
  private borrowerValidated: boolean = false;
  private borrowerDetails: any = null;

  /**
   * Control state changes
   */
  private initialState: any = {};

  $refs!: {
    dropdownMenu: any,
    dropdownTrigger: any,
    disclosure: any,
    sign: any,
    disclosureForm: any,
    disclosuresList: any,
    disclosuresSelectList: any,
    dfl: any,
    signatureCanvas: any,
  };

  get isBorrowerOrBrokerLoanSubmission () {
    return this.sourceType === 'borrower' || (['broker', 'mlo'].includes(this.sourceType) && !this.isLoanSubmission);
  }

  get canShowWesign () {
    return ['broker', 'mlo'].includes(this.sourceType) && this.isLoanSubmission && !this.$store.state.wemloLoader && !this.isDisclosuresDeclined
  }

  public async submit(checkUnsaved = false) {
    try {
      if (this.renderCase === "signature") {
        return (await this.saveSignature());
      } else if (this.renderCase === "disclosure") {
        return (await this.submitDisclosureData(checkUnsaved));
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  public checkAllFormSigned(userId: String = this.$userId) {
    return this.orderedDisclosures.every(disclosure =>
      disclosure.fields
        .filter(field => field.type === 'wesign')
        .filter(field => field.needToBeSignedBy.includes(userId))
        .every(field => !!field.data)
    );
  }

  public toggleDisclosuresList() {
    this.showDisclosuresList = !this.showDisclosuresList;
  }

  public goToDisclosure(wesignIdentifier) {
    const disclosure:any = _.head(this.$refs[`disclosureForm_${wesignIdentifier}`]);

    if (!disclosure) { return; }

    disclosure.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' });
    this.showDisclosuresList = false;
  }
  
  public disclosuresListScroll({ target }) {
    const disclosuresListRect = target.getBoundingClientRect();
    
    const disclosuresForms = _.flatten(Object.keys(this.$refs)
        .filter(ref => ref.startsWith('disclosureForm_'))
        .map(ref => this.$refs[ref]));

    this.visibleDisclosure =
      _.head(disclosuresForms.reduce((aggregator, disclosure, index) => {
        const { top, bottom, height } = disclosure.getBoundingClientRect();

        const visible = top <= disclosuresListRect.top
          ? disclosuresListRect.top - top <= height
          : bottom - disclosuresListRect.bottom <= height;

        if (visible && _.get(disclosure, 'dataset.wesignidentifier')) {
          aggregator.push(disclosure.dataset.wesignidentifier);
        }

        return aggregator;
      }, []));
  }

  public getCurrentFormIndex() {
    const disclosuresForms = _.flatten(Object.keys(this.$refs)
        .filter(ref => ref.startsWith('disclosureForm_'))
        .map(ref => this.$refs[ref]));

    const currentVisibleForm = disclosuresForms.find(form => _.get(form, 'dataset.wesignidentifier') === this.visibleDisclosure);
    const currentVisibleFormWesignIdentifier = _.get(currentVisibleForm, 'dataset.wesignidentifier');

    return this.orderedDisclosures.findIndex(disclosure => _.get(disclosure, 'wesignIdentifier') === currentVisibleFormWesignIdentifier);
  }

  public goToPreviousDisclosure() {
    const currentVisibleFormIndex = this.getCurrentFormIndex();
    const previousForm = this.orderedDisclosures[currentVisibleFormIndex - 1];

    if (previousForm) {
      this.goToDisclosure(previousForm.wesignIdentifier);
    }
  }

  public goToNextDisclosure() {
    const currentVisibleFormIndex = this.getCurrentFormIndex();
    const previousForm = this.orderedDisclosures[currentVisibleFormIndex + 1];

    if (previousForm) {
      this.goToDisclosure(previousForm.wesignIdentifier);
    }
  }

  public clickOnPage(e) {
    if (!_.get(this.$refs.dropdownMenu, 'contains', false) || !_.get(this.$refs.dropdownTrigger, 'contains', false)) { return; }

    if (!this.$refs.dropdownMenu.contains(e.target) && !this.$refs.dropdownTrigger.contains(e.target)) {
      this.showDisclosuresList = false;
    }
  }

  public updatePendingSignatures() {
    this.pendingSignatures = this.$refs['disclosure']
      .map(disclosure => disclosure.$refs['page'])
      .filter(Boolean)
      .flat()
      .map(page => page.$refs['signature'])
      .filter(Boolean)
      .flat()
      .filter(signature => signature.pendingMySignature);
  }

  public goToNextSignature() {

    this.updatePendingSignatures();

    if (!this.pendingSignatures.length) { return; }

    const signature = _.head(this.pendingSignatures);

    signature['$el'].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
  }

  updateSignatureInRealTime() {
    if (!this.textSignature2DContext && this.$refs.signatureCanvas) {
      this.textSignature2DContext = this.$refs.signatureCanvas.getContext('2d');

      this.$refs.signatureCanvas.height = 150;
      this.$refs.signatureCanvas.width = 407;
      this.textSignature2DContext.textBaseline = 'middle';
      this.textSignature2DContext.textAlign = "center";
    }

    if (!this.textSignature2DContext) { return; }

    let fontSize = 50;
    this.textSignature2DContext.font = `${fontSize}px Yellowtail`;
    let textWidth = this.textSignature2DContext.measureText(this.typedSignature).width;

    while (textWidth + 30 > this.$refs.signatureCanvas.width) {
      fontSize--;
      this.textSignature2DContext.font = `${fontSize}px Yellowtail`;
      textWidth = this.textSignature2DContext.measureText(this.typedSignature).width;
    }

    this.textSignature2DContext.clearRect(0, 0, this.$refs.signatureCanvas.width, this.$refs.signatureCanvas.height);
    this.textSignature2DContext.fillText(this.typedSignature, (this.$refs.signatureCanvas.width / 2), (this.$refs.signatureCanvas.height / 2));
  }

  convertTypedSignatureToPng() {
    if (!this.$refs.signatureCanvas) { return ''; }

    return this.$refs.signatureCanvas.toDataURL();
  }

  getSignatureString() {
    switch (this.activeTabId) {
      case 'draw':
        return this.$refs.sign["extractSignatureData"]();
      case 'upload':
        return this.uploadedSignature;
      case 'type':
        return this.convertTypedSignatureToPng();
      default:
        return;
    }
  }

  private async saveSignature() {
    try {
      const signatureString = this.getSignatureString();

      if (!signatureString) { return false; }

      await this.$dialog.confirm("Are you sure you want to save this signature?");

      this.$store.state.wemloLoader = true;

      const response = await Axios.post(
        BASE_API_URL + "disclosure/saveSignature",
        {
          secureString: signatureString,
          userId: this.userId,
          userType: this.$userType,
          loanTxnId: this.loanTxnId
        }
      );

      this.$store.state.wemloLoader = false;

      if (response.data.statusCode === 200) {
          this.$snotify.success(response.data.message);
          this.$emit("saveEvent");
          this.$modal.hide("digitalSignature");
          this.$emit('updateSignatureStatus', true);
      } else {
        throw new Error(response.data);
      }

      this.checkStatusOfSignature();
      return false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      return false;
    }
  }

  onDisclosuresListChange(disclosuresList) {
    if (this.disclosureRefusal) {
      this.sendNowOrLater = "sendLater"
      this.disclosureRefusal = false;
    }

    let newDisclosure = null;

    disclosuresList
      .forEach(disclosure => {
        const selectedDisclosures = disclosure.formId
          ? this.disclosures.filter(d => d.formId === disclosure.formId)
          : this.disclosures.filter(d => d.wesignIdentifier === disclosure.wesignIdentifier);

        selectedDisclosures.forEach(d => {
          if (d.documentStatus === 'Wesign Not Required' && disclosure.documentStatus === 'Wesign Required') {
            newDisclosure = d;
          }

          d.documentStatus = disclosure.documentStatus
        });
      });

    if (!this.visibleDisclosure) {
      this.visibleDisclosure = _.get(_.head(this.disclosures), 'wesignIdentifier');
    }

    if (newDisclosure) {
      setTimeout(function() {
        this.goToDisclosure(newDisclosure.wesignIdentifier);
      }.bind(this), 200);
    }
  }

  private async saveAndSubmitBorrowerData() {
    const isSubmitted = await this.submitDisclosureData();
    
    if (isSubmitted) {
      if (this.borrowerDetails && this.borrowerDetails.coBorrower) {
        const borrowers = [
          this.borrowerDetails.borrower.userId,
          this.borrowerDetails.coBorrower.userId,
        ]
        
        if (!borrowers.every(userId => this.checkAllFormSigned(userId))) {
          this.$modal.show('modalSignForAnotherBorrower');
          return;
        }
      }

      this.$router.push('/borrower/dashboard')
    }
  }

  get sendNow() {
    if (['broker', 'mlo'].includes(this.sourceType)) {
      return this.sendNowOrLater === "sendNow"
    }
    return false
  }

  public handleSendOrLaterClick() {
    this.disclosureRefusal = false;
    this.isDiclineToSend = false;
  }

  async refuseDisclosure() {
    this.disclosureRefusal = true;
    this.$modal.show('confirmSendingNoneModal');
  }

  private deselectAllDisclosuresList() {
    if (this.$refs.disclosuresSelectList) {
      const selectLists = _.isArray(this.$refs.disclosuresSelectList)
          ? this.$refs.disclosuresSelectList
          : [ this.$refs.disclosuresSelectList ];

      selectLists.forEach(list => { list['deselectAll']() });
    }
  }

  private async confirmSendingNone() {
    if (this.isDiclineToSend) {
      this.isDiclineToSend = false;
      await this.submitDisclosureData(false, true);
      this.$modal.hide('confirmSendingNoneModal');
      this.$router.go(0);
      return;
    }

    this.sendingNoneConfirmation = true;

    if (this.disclosureRefusal) {
      this.deselectAllDisclosuresList()
      await sleep(500);
    }

    this.$modal.hide('confirmSendingNoneModal');
    this.$emit('triggersubmit', false);
  }

  public getUpdateRequestBody() {
    return {
      disclosures: this.disclosures,
    };
  }

  private checkUnsavedDisclosures(initialState, currentState) {
    const filterRequired = (disclosure) => disclosure.documentStatus === "Wesign Required";
    const mapAnnotatedCategoryName = (disclosure) => disclosure.annotatedCategoryName;

    const initialStateOfDisclosures = initialState.disclosures.filter(filterRequired).map(mapAnnotatedCategoryName);
    const currentStateOfDisclosures = currentState.disclosures.filter(filterRequired).map(mapAnnotatedCategoryName);

    const added = _.difference(currentStateOfDisclosures, initialStateOfDisclosures);
    const removed = _.difference(initialStateOfDisclosures, currentStateOfDisclosures);

    return _.concat(added, removed)
      .map(title => ({
        title,
        nested: [],
      }));
  }

  private removeAllDisclosures(disclosures) {
    disclosures.forEach(disclosure => _.set(disclosure, 'documentStatus', 'Wesign Not Required'));
  }

  private submitDeclineToSend() {
    this.isDiclineToSend = true;
    this.$modal.show('confirmSendingNoneModal');
  }

  private async submitDisclosureData(checkUnsaved = false, isDeclineToSend = false) {
    if (!this.checkAllFormSigned(this.userId)) {
      this.$modal.show('formValidationModal');
      return false;
    }

    if (!this.orderedDisclosures.length && !this.sendingNoneConfirmation && !isDeclineToSend) {
      this.$modal.show('confirmSendingNoneModal');
      return false;
    }

    const unsavedDisclosures = this.checkUnsavedDisclosures(this.initialState, this.getUpdateRequestBody());

    if (checkUnsaved && unsavedDisclosures) {
      return { unsavedFields: unsavedDisclosures };
    }

    try {
      this.$store.state.wemloLoader = true;

      if (isDeclineToSend) {
        this.removeAllDisclosures(this.disclosures);
      }

      const response = await Axios.post(
        BASE_API_URL + "disclosure/saveDataOfDisclosures",
        {
          loanTxnId: this.loanTxnId,
          loanType: this.loanType,
          disclosures: this.disclosures,
          signerId: this.userId,
          sendNow: this.sendNow,
        });

      if (this.sendingNoneConfirmation && !this.isDisclosuresDeclined) {
        await this.showDeclineToSendBrokerDisclosuresDoc()
      }

      this.$snotify.success(response.data.message);
      this.$emit("saveEvent");
      this.$store.state.wemloLoader = false;
      this.isBrokerRefusesDisclosures();
      return true;
    } catch (error) {
      console.log(error);
      if (error.response.data === 'wesign fields not signed') {
        this.$modal.show('formValidationModal');
      }
      this.$store.state.wemloLoader = false;

      return false;
    }
  }

  public async showDeclineToSendBrokerDisclosuresDoc() {
    this.originalAdditionalDisclosures = await this.getAdditionalDisclosures(this.$brokerId, this.loanTxnId);
    this.originalAdditionalDisclosures.forEach(this.documentSetup);
    this.disclosures = _.cloneDeep(this.originalAdditionalDisclosures);
  }

  fillWesignDate(disclosure, field) {
    const wesignDateField = disclosure.fields.find(f => f.fieldMapName === field.fieldMapName + '.date');

    if (!wesignDateField) { return; }

    const wesignDate = disclosure.wesignData.find(wesignData => wesignData.fieldId === wesignDateField.fieldId);

    if (!wesignDate || wesignDate.data || wesignDateField.data) { return; }

    wesignDateField.data = (new Date()).toLocaleDateString('en-US');
    wesignDateField.updatedOn = new Date();

    wesignDate.data = _.get(wesignDateField, 'data', null);
    wesignDate.updatedOn = _.get(wesignDateField, 'updatedOn', null);
  }

  updateDisclosuresFields({ documentFields }, disclosure) {

    disclosure.wesignData = disclosure.wesignData.map(wesignData => {
      const field = documentFields.find(f => f.fieldId === wesignData.fieldId);

      if (_.get(field, 'data', null) && _.get(field, 'data') !== _.get(wesignData, 'data') && _.get(field, 'type') === 'wesign') {
        this.fillWesignDate(disclosure, field);
      }

      wesignData.data = _.get(field, 'data', null);
      wesignData.updatedOn = _.get(field, 'updatedOn', null);

      return wesignData;
    });

    setTimeout(function() {
      this.updatePendingSignatures();
    }.bind(this), 300);
  }

  private async checkStatusOfSignature() {
    this.$store.state.wemloLoader = true;
    try {

      const dataObj = {
        userType: this.$userType,
        userId: this.userId,
      };

      const { data: { secureString = null } } = await Axios.post(
        BASE_API_URL + "common/fetchSignature",
        dataObj
      );

      this.signature = secureString;

      const renderSignaturePage: boolean = !!this.signature;

      /**
       * If there is no signature in the database we must render the signature component
       */
      await this.renderPage(renderSignaturePage);
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  private async renderPage(renderDisclosures) {
    this.renderCase = renderDisclosures ? "disclosure" : "signature";

    if (renderDisclosures) {
      await this.fetchDefaultDisclosure();
    }
  }

  get orderedDisclosures() {
    const ordered = _.orderBy(this.disclosures, ['order', 'formId']);
    return this.isDisclosuresDeclined ? ordered : ordered.filter(disclosure => disclosure.documentStatus === 'Wesign Required');
  }

  generateNewHash() {
    return Math.random().toString();
  }

  private async getLoanDisclosures() {
    try {
      this.$store.state.wemloLoader = true;

      if (this.sourceType === 'borrower') {
        return await Axios.post(
          BASE_API_URL + "disclosure/getLoanDisclosuresByBorrowerId",
          {
            borrowerId: this.userId,
            loanTxnId: this.loanTxnId,
          },
        );
      } else {
        return await Axios.post(
          BASE_API_URL + "disclosure/getLoanDisclosures",
          {
            userId: this.$brokerId,
            loanTxnId: this.loanTxnId,
          },
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.state.wemloLoader = false;
    }

    return {
      data: {
        loanType: null,
        disclosures: [],
      }
    }
  }

  private async fetchDefaultDisclosure() {
    try {
      this.$store.state.wemloLoader = true;
      const { data: { loanType, disclosures } } = await this.getLoanDisclosures();

      if (!this.isLoanSubmission && ['processor', 'broker', 'mlo'].includes(this.sourceType)) {
        this.originalAdditionalDisclosures = await this.getAdditionalDisclosures(this.$brokerId, this.loanTxnId);
      }

      this.originalDisclosures = disclosures;

      this.loanType = loanType;

      this.originalDisclosures.forEach(this.documentSetup);
      this.originalConditionalDisclosures.forEach(this.documentSetup);
      this.originalAdditionalDisclosures.forEach(this.documentSetup);

      this.originalConditionalDisclosures = this.originalAdditionalDisclosures.filter(disclosure => disclosure.type === 'Condition');
      this.originalAdditionalDisclosures = this.originalAdditionalDisclosures.filter(disclosure => disclosure.type !== 'Condition')

      this.disclosures = [
        ..._.cloneDeep(this.originalDisclosures),
        ..._.cloneDeep(this.originalAdditionalDisclosures),
        ..._.cloneDeep(this.originalConditionalDisclosures),
      ];
      this.isBrokerRefusesDisclosures();

      if (this.isDisclosuresDeclined) {
        await this.showDeclineToSendBrokerDisclosuresDoc()
      }

      this.initialState = this.cloneObject(this.getUpdateRequestBody());
      this.visibleDisclosure = _.get(_.head(this.disclosures), 'wesignIdentifier');
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingDisclosuresPage = false;
      this.$store.state.wemloLoader = false;
    }
  }

  public documentSetup(disclosure) {
    disclosure.fields.forEach(field => {
      const wesignData = disclosure.wesignData.find(wesignData => wesignData.fieldId === field.fieldId);
      field.needToBeSignedBy = _.get(wesignData, 'needToBeSignedBy', []);
      field.data = _.get(wesignData, 'data', null);
    });

    disclosure.uploadedFile = null;

    const isURLAdocument = _.startsWith(disclosure.wesignIdentifier, 'URLA-');

    if (_.get(_.last(disclosure.fileHistory), 'generated') === false || isURLAdocument) {
      disclosure.uploadedFile = _.get(_.last(_.get(_.last(disclosure.fileHistory), 'files', [])), 'path', null);
    }

    if (!isURLAdocument && disclosure.uploadedFile) {
      disclosure.fields = [];
      disclosure.wesignData = [];
    }

    if (isURLAdocument) {
      disclosure.fields = disclosure.fields.filter(field => !field.data);
    }

    disclosure.hash = this.generateNewHash();
  }

  public async getAdditionalDisclosures(userId, loanTxnId) {
    const { data: { disclosures: allAdditionalDisclosures = [] } } = await Axios.post(
      `${BASE_API_URL}disclosure/getLoanAdditionalDisclosures`,
      { userId, loanTxnId }
    );

    return allAdditionalDisclosures.filter(disclosure => _.get(disclosure, 'documentStatus') === 'Wesign Required');
  }

  async saveFields() {
    try {

      const response = await Axios.post(
        BASE_API_URL + "disclosure/saveWesignData",
        {
          disclosures: this.disclosures,
          loanTxnId: this.loanTxnId,
          signerId: this.userId,
        });

      this.$snotify.success(response.data.message);
      this.$emit("saveEvent");

    } catch (error) {
      console.log(error);
    }
  }

  public async beforeRevokePackage() {
    this.$modal.show('beforeRevokePackageModal');
  }

  public async revokePackage() {

    try {
      this.$store.state.wemloLoader = true;

      await Axios.post(
        BASE_API_URL + "disclosure/revokeDisclosurePackageAPI",
        { loanTxnId: this.$route.query.id }
      );

      this.$modal.hide('beforeRevokePackageModal');
      this.$store.state.wemloLoader = false;
      this.$router.go(0);

      this.$snotify.success('Disclosures Package revoked', { timeout: 1000 });
      this.$emit("saveEvent");
    } catch (error) {
      console.error(error);
    }

  }

  public async revokeSpecificDisclosures(disclosures) {

    try {
      const response = await Axios.post(
        BASE_API_URL + "disclosure/revokeSpecificDisclosures",
        { loanTxnId: this.$route.query.id, disclosures });

      this.disclosures.forEach(disclosure => {
        const updatedDisclosure = response.data.disclosures.find(updatedDisclosure => disclosure.wesignIdentifier === updatedDisclosure.wesignIdentifier);

        if (!updatedDisclosure) { return; }

        disclosure.fields = updatedDisclosure.fields.map(field => {
          const wesignData = updatedDisclosure.wesignData.find(wesignData => wesignData.fieldId === field.fieldId);
          field.data = _.get(wesignData, 'data', null);
          field.needToBeSignedBy = _.get(wesignData, 'needToBeSignedBy', []);

          return field;
        });

        disclosure.uploadedFile = null;
        disclosure.hash = this.generateNewHash();
      });

      this.$snotify.success(response.data.message);
      this.$emit("saveEvent");
    } catch (error) {
      console.error(error);
    }
  }

  public async reprocessLoanDocuments() {

    try {
      const response = await Axios.post(
        BASE_API_URL + "disclosure/disclosurePackagePostProcessing",
        { loanTxnId: this.$route.query.id });

      this.$snotify.success(response.data.message);
    } catch (error) {
      console.error(error);
    }
  }

  async mounted() {
    document.removeEventListener('click', this.clickOnPage, true);
    document.addEventListener('click', this.clickOnPage, true);

    const eDisclosureAgreement = _.get(this.$store.state.sessionObject, 'pendingActions.eDisclosureAgreement', null);

    if (!eDisclosureAgreement && this.$userType === 'Borrower') {
      this.$router.push('/borrower/dashboard');
    }

    this.userId = String(this.$userId)
    this.loanTxnId = String(this.$route.query.id);

    await this.$emit("checkChangesInLe");

    if (this.sourceType === 'processor') {
      this.renderPage('processor');
    } else if (this.sourceType === 'borrower') {
      await this.getBorrowerDetails();
      this.renderPage(null);
    } else {
      await this.checkStatusOfSignature();
    }
  }

  beforeDestroy() {
    document.removeEventListener('click', this.clickOnPage, true);
  }

  public async onBorrowerValidated({ userId, isValid }) {
    this.userId = userId
    this.borrowerValidated = isValid

    if (isValid) {
      await this.checkStatusOfSignature();
      this.emitChangeWesignParty(userId);
    }
  }

  private emitChangeWesignParty(userId) {
    const borrowers = [this.borrowerDetails.borrower, this.borrowerDetails.coBorrower];

    this.$emit('changeWesignParty', borrowers.find(borrower => borrower && borrower.userId === userId));
  }

  private onChooseYesToSignForAnotherBorrower() {
    this.$modal.hide('modalSignForAnotherBorrower');

    this.userId = this.$userId
    this.borrowerValidated = false

    this.renderPage(null)
  }

  private setActiveTab(id) {
    this.activeTabId = id;
  }

  private async uploadSignature(event) {

    const signature = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(event.target.files[0]);
    });

    this.uploadedSignature = signature as string;
  }

  private onChooseNoToSignForAnotherBorrower() {
    this.$modal.hide('modalSignForAnotherBorrower');
    this.$router.push('/borrower/dashboard');

  }
  
  private get session() {
    return this.$store.state.sessionObject;
  }

  private async getBorrowerDetails() {
    try {
      const response = await Axios.post(
        BASE_API_URL + "pos/getLogInBorrowerDetails",
        {
          loanTxnId: this.loanTxnId,
          borrowerId: this.session.borrowerId,
        },
        {
        }
      );
        
      this.borrowerDetails = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  private isBrokerRefusesDisclosures() {
    try {
      let requiredDisclosures = this.disclosures.filter(disclosure => disclosure.documentStatus === 'Wesign Required');
      this.isDisclosuresDeclined = false;
      if(requiredDisclosures.every(disclosure => disclosure.propagation === 'brokerRefusesDisclosures')) this.isDisclosuresDeclined = true;
    } catch (error) {
      console.log(error);
    }
  }

  private isOnlyDeclineDisclosure() {
    if(this.originalAdditionalDisclosures.length == 1 && this.originalAdditionalDisclosures[0].propagation === 'brokerRefusesDisclosures') return true;
  }
}
