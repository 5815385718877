import _ from "lodash";
import { Component, Vue } from "vue-property-decorator";

const FIELDS_TITLE_MAPPING = {
    loanInformation: 'Loan Information',
    tridApplicationDate: 'TRID Application date',
    amortizationType: 'Amortization',
    loanPurpose: 'Loan Purpose',
    purposeOfRefinance: 'Refinance Types',
    originalCost: 'Original Cost',
    amountExistingLiens: 'Amount Existing Liens',
    refinanceProgram: 'Refinance Program',
    otherRefinanceProgram: 'Refinance Program',
    productType: 'Product Type',
    fhaloanLenderIdentifier: 'FHA Loan Lender',
    sectionOfActType: 'Section of Act Type',
    governmentMortgageCreditCertificateAmount: 'Government Mortgage Credit Certificate Amount',
    veteranStatus: 'Veteran Status',
    vaEntitlementAmount: 'VA Entitlement Amount',
    closingDate: 'Est. Closing Date',
    fixedPeriodEffectiveMonthCount: 'Fixed Period Effective Month Count',
    subsequentAdjustmentPeriod: 'Subsequent Adjustment Period',
    isBalloon: 'Final Balloon Payment Required',
    balloonTermMonthCount: 'Balloon Term',
    isInterestOnly: 'Interest Only',
    interestOnlyTermMonthCount: 'Interest Only Term',
    isBuydownTempSubsidyFunding: 'Temporary Interest Rate Buydown',
    buydownInitialEffectiveInterestRate: 'Temporary Interest Rate Buydown Term',
    impounds: 'Escrow',
    isFirstTimeHomeBuyer: 'First time home buyer?',
    havePowerOfAttorney: 'POA',
    isPUD: 'PUD',
    isPropertyInProject: 'Property in project',
    isNegativeAmortization: 'Negative Amortization',
    propertyValue: 'Property Value',
    loanAmount: 'Loan Amount',
    LTV: 'LTV(%)',
    interestRate: 'Interest Rate(%)',
    FICO: 'FICO',
    term: 'Term (years)',
    creditReportFirstPulledDate: 'Date Credit Was Pulled',
    assetsInfoDetails: 'Assets',
    assetsCreditDetails: 'Purchase Credits',
    giftsInfoDetails: 'Gifts',
    declarationData: 'Declaration Questions',
    borrowerInfo: 'Borrower Information',
    borrowerData: 'Borrower Information',
    demographicData: 'Demographic Data',
    incomeData: 'Income Details',
    financialInfo: 'Financial Information',
    realEstate: 'Real Estate',
    toBeDecidedProperty: 'Is this Property TBD',
    propertyAddressSameAsCurrentAddress: 'Is this your present address?',
    propertyAddress: 'Property Address',
    constructionCase: 'Construction Case',
    closingType: 'Closing Type',
    propertyType: 'Property Type',
    attachmentType: 'Attachment Type',
    numberOfUnits: '# of Units',
    underConstruction: 'Under Construction?',
    borrowerLivesInCommunityProperty: 'At least one borrower lives in a community property state',
    propertyInCommunityProperty: 'The property is in a community property state',
    conversionOfContractForDeedIndicator: 'Conversion of Contract for Deed or Land Contract',
    livingArea: 'Living Area',
    yearBuilt: 'Year Built',
    appraisedValue: 'Appraised Value',
    occupancy: 'Occupancy Type',
    explanation: 'Legal Description',
    manner: 'Title Manner Held',
    'titleInformation.otherManner': 'Proposed Title Manner Held',
    titleInformation: 'Title Information',
    currentTitleInformation: 'Current Title Manner Held',
    expirationDate: 'Expiration Date',
    estate: 'Estate',
    nativeAmericanLandsType: 'Indian Country Land Tenure',
    trustInformation: 'Trust Information',
    additionalLoan: 'Additional Loans',
    realtorInformation: 'Realtor Information',
    generateRentalIncome: 'Use Rental Income?',
    occupancyRate: 'Occupancy Rate',
    properties: 'Properties',
    transactionDetails: 'Transaction Details',
    purchasePrice: 'a. Purchase price',
    alternationImprovementRepair: 'b. Alterations, improvements, repairs',
    land: 'c. Land (if acquired separately)',
    refinance: 'd. Refinance (incl. debt to be paid off)',
    prepaidItem: 'e. Estimated prepaid item',
    closingCosts: 'f. Estimated closing costs',
    fundingFee: 'g. PMI, MIP, Funding Fee',
    discount: 'h. Discount (if Borrower will pay)',
    subOrdinateFinancing: 'j. Subordinate financing',
    closingCostPaidBySeller: 'k. Closing costs paid by seller',
    otherCredits: 'Other Credits',
    fundingFeeFinanced: 'n. PMI, MIP, Funding Fee Financed',
    loanEstimateDetails: 'Loan Estimate Details',
    originationCharges: 'Origination Charges',
    percentageOfLoanAmount: '% of loan amount (points)',
    chargesOfLoanAmount: 'Charges Of Loan Amount',
    uwFee: 'Underwriting Fee',
    waive: 'Waive',
    origination: 'Origination',
    amount: 'Amount',
    cannotShopFor: 'B. Services You Cannot Shop For',
    canShopFor: 'C. Services You Can Shop For',
    taxesOtherGovtFees: 'Recording Fees And Other Taxes',
    transferTaxes: 'Transfer Taxes',
    prepaids: 'F. Prepaids',
    ownersInsurancePremium: 'Owners Insurance Premium',
    mortgageInsurancePremium: 'Mortgage insurance premium',
    propertyTaxes: 'Property Taxes',
    prepaidIntrest: 'Prepaid Interest',
    escrowPaymentAtClosing: 'G. Initial Escrow Payment At Closing',
    lenderCredits: 'Lender Credits',
    fundsFromBorrower: 'Down payments/Funds From Borrower',
    fundsForBorrower: 'Funds For Borrower',
    sellerCredits: 'Seller credits',
    deposit: 'Deposit',
    estimatedTotalPayment: 'Estimated Total Payoffs and Payments',
    estimatedCashToClose: 'Estimated Cash To Close',
    estimatedClosingCost: 'Estimated Closing Costs Financed (Paid from your Loan Amount)',
    personalInfo: 'Personal Information',
    name: 'Name',
    type: 'Type',
    value: 'Value',
    middleName: 'Middle Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    nameSuffix: 'Name Suffix',
    creditorName: 'Creditor Name',
    accountNumber: 'Account Number',
    additionalLoans: 'Additional Loans',
    legalDescription: 'Legal Description',
    loanAndProperty: 'Loan And Property',
    creditType: 'Credit Type',
}

const isConstantObject = (value) => ['ppeValue', 'ediValue', 'mismoValue'].some(prop => value.hasOwnProperty(prop));

const getTitle = (fieldKey) => FIELDS_TITLE_MAPPING[fieldKey] || _.startCase(fieldKey);

const getFieldsWithTitle = (field) => {
    if (_.isNil(field) || !_.isObject(field)) {
        return [];
    }

    return Object.keys(field)
        .filter(fieldKey => !_.isUndefined(field[fieldKey]))
        .map((fieldKey) => (
            {
                title: getTitle(fieldKey),
                nested: getFieldsWithTitle(field[fieldKey]),
            }
        ));
};

const getChanges = (object, base) => _.transform(object, (result, value, key) => {
    const source = _.get(base, key);

    if (!_.isEqual(value, source)) {
        if (_.isObject(value) && !_.isDate(value)) {
            const differences = getChanges(value, source);

            if (!_.isEmpty(differences)) {
                if (isConstantObject(value)) {
                    result[key] = {};
                } else {
                    result[key] = differences;
                }
            }
        } else {
            result[key] = value;
        }
    }
});

@Component
export default class SaveInformationMixin extends Vue {

    /* Function to compare 2 objects of the same type and show the difference between them */
    public difference(updatedObject, initialObject) {
        /**
         * Get removed or modified changes
         */
        const changesFromInitialState = getChanges(initialObject, updatedObject);
        
        /**
         * Get added or modified changes
         */
        const changesFromFinalState = getChanges(updatedObject, initialObject);

        return getFieldsWithTitle(_.merge(changesFromInitialState, changesFromFinalState));
    }

    public notifyTosty(code, message) {
        this.$snotify.clear();
        this.$snotify[code === 200 ? "success" : "error"](message);
    }

    public cloneObject(object: any): any {
        return JSON.parse(JSON.stringify(object));
    }

}
