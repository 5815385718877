import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import _ from "lodash";
import SaveLosButton from "../views/SaveLosButton.vue";

@Component({ components: {  SaveLosButton }})
export default class LOSMixin extends Vue {
  public submitToWemloWarningMessage: any = null;
  public saveInProcess = false;

  public async checkSubmitToWemloButtonHaveActive() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "los/getWemloSettingDetails",
        { loanTxnId: this.$route.query.id }
      );
      if (response.status == 200) {
        this.submitToWemloWarningMessage = response.data.message;
        return response.data.status;
      }
    } catch (error) {
      console.log(error);
      return true;
    }
  }

  getBorrowerByIndex(borrowers, index) {
    return _.nth(borrowers, index);
  }

  getBorrowerName(borrower) {
    const { firstName = '', lastName = '' } = _.get(borrower, 'personalInfo.name', {});

    return `${firstName} ${lastName}`;
  }

  getBorrowerNameByIndex(borrowers, index) {
    return this.getBorrowerName(this.getBorrowerByIndex(borrowers, index));
  }
  
  public years(start: number, end: number) {
    let years = [];
    for (let i = end; i > start; i--) {
      years.push(i);
    }
    return years;
  }

  get days() {
    let days = [];
    for (let i: any = 1; i < 32; i++) {
      i = i < 10 ? "0" + i : i;

      days.push(i);
    }
    return days;
  }

  get months() {
    let months = [];
    for (let i: any = 1; i < 13; i++) {
      i = i < 10 ? "0" + i : i;
      months.push(i);
    }
    return months;
  }

  public count(startMonth, endMonth, startYear, endYear) {
    let start = startYear != endYear ? '01' : startMonth ? startMonth : '01';
    let months = [];
    for (let i = start; i < endMonth; i++) {
      i = i < 10 && i > start ? "0" + i : i;
      months.push(i);
    }
    return months;
  }

  get yearsPeriod() {
    let period = [];
    for (let i: any = 1; i < 101; i++) {
      i = i < 10 ? "0" + i : i;
      period.push(i);
    }
    return period;
  }

  /***************************************************************************
   *      Calculate Gross Monthly Income 
   * @param obj 
   */
  public getGrossMonthlyIncome(obj) {
    let arr = [];
    for (const key in obj) {
      if (
        key != "militaryEntitlements" &&
        obj.hasOwnProperty(key) &&
        obj[key].monthlyIncome != undefined &&
        obj[key].monthlyIncome != "" &&
        obj[key].monthlyIncome != null
      )
        arr.push(obj[key].monthlyIncome);
    }
    let sum = arr.reduce(function (a, b) {
      return parseFloat(a) + parseFloat(b);
    }, 0);
    return sum;
  }

  public getSum(arr?: any, variable?: string) {
    if(!arr) return
    arr = arr.filter(item =>
      variable
        ? this.$lodash.get(item, variable)
        : item != undefined && variable
          ? this.$lodash.get(item, variable)
          : item != "" && variable
            ? this.$lodash.get(item, variable)
            : item != null && variable
              ? this.$lodash.get(item, variable)
              : item
    );
    arr = arr.map(a => (variable ? this.$lodash.get(a, variable) : a));
    arr = arr.reduce(function (a, b) {
      return parseFloat(a) + parseFloat(b);
    }, 0);
    return arr;
  }

  /********************************************************************************************************************************
   *          Calculate FundngFee based on mortgageAppliedFor(ProductType), loanAmount, propertyValue 
   *          fundingfee calculated only for FHA(ediValue: 03), VA(ediValue:02) Loan's
   *          data :=> { mortgageAppliedFor, loanAmount, purchasePrice, veteranStatus, loanPurpose}
   ********************************************************************************************************************************/
  public calculateFundingFee(loanAndProperty) {
    let fundingFee:any = 0;
    let feePercentage = 0;
    try {
      let loanAmount = parseFloat(loanAndProperty.loanAmount);
      if (loanAndProperty.mortgageAppliedFor) {
        const veteranStatus = loanAndProperty.veteranStatus;
        if (loanAndProperty.mortgageAppliedFor.ediValue == '03') {
            feePercentage = 1.75;
        } else if (loanAndProperty.mortgageAppliedFor.ediValue == '02' && veteranStatus) {
          let purchasePrice = parseFloat(loanAndProperty.purchasePrice);
            let downPayment = purchasePrice - loanAmount;
            let downPaymentPercentage = (downPayment / purchasePrice) * 100;
            const loanPurposeValueEdiValue = loanAndProperty.loanPurpose ? loanAndProperty.loanPurpose.ediValue : null;
            const purposeOfRefinanceEdiValue = loanAndProperty.refinanceCase && loanAndProperty.refinanceCase.purposeOfRefinance ? loanAndProperty.refinanceCase.purposeOfRefinance.ediValue : null;
            let isFirstTimeUse = ['Active Duty – First time use', 'Reserve or Guard – First use'].includes(veteranStatus);
            let isSubsequentUse = ['Active Duty – Subsequent use', 'Reserve or Guard – Subsequent use'].includes(veteranStatus);
            if (loanPurposeValueEdiValue == '16') { // If Loan Purpose is Purchase
                if (isFirstTimeUse){
                    if(downPaymentPercentage < 5){
                        feePercentage = 2.3;
                    } else if (downPaymentPercentage >= 5 && downPaymentPercentage < 10){
                        feePercentage = 1.65;
                    } else if(downPaymentPercentage >= 10){
                        feePercentage = 1.4;
                    }
                }else if (isSubsequentUse){
                    if(downPaymentPercentage < 5){
                        feePercentage = 3.6;
                    } else if (downPaymentPercentage >= 5 && downPaymentPercentage < 10){
                        feePercentage = 1.65;
                    } else if(downPaymentPercentage >= 10){
                        feePercentage = 1.4;
                    }
                }
            } else if (loanPurposeValueEdiValue == '05' && purposeOfRefinanceEdiValue) { // If Loan Purpose is Cash-Out Refinance
                if (purposeOfRefinanceEdiValue == '01') {
                    if (isFirstTimeUse){
                        feePercentage =  2.3;
                    } else if (isSubsequentUse){
                        feePercentage = 3.6;
                    }
                }
                else if (purposeOfRefinanceEdiValue == 'F1') {
                    if (veteranStatus != 'Disabled Veteran')
                        feePercentage = 0.5;
                    else
                        feePercentage = 0
                }
            }
        }
        if (feePercentage > 0) {
            feePercentage = (feePercentage / 100)
            fundingFee = loanAmount * feePercentage;
            fundingFee = parseFloat(fundingFee).toFixed(2);
        }
      }
    } catch (error) {
      console.log(error);
    }
    
    return {fundingFee:fundingFee,feePercentage:feePercentage};
  }


  public calculateMortgageInsurance(data) {
    try {
      let loanAmount = parseFloat(data.loanAmount);
      let term = parseFloat(data.term);
      let LTV = parseFloat(data.LTV);
      let mortgageInsurance = 0;
      let fhaData: any = {};

      if (term > 15 && loanAmount <= 625500 && LTV <= 95) {
        fhaData.mip = 80;
        fhaData.LTV = '<= 95';
        fhaData.loanAmount = '<= 625,500';
        fhaData.term = '> 15';
      }
      else if (term > 15 && loanAmount <= 625500 && LTV > 95) {
        fhaData.mip = 85;
        fhaData.LTV = '> 95';
        fhaData.loanAmount = '<= 625,500'
        fhaData.term = '> 15'
      }

      else if (term > 15 && loanAmount > 625500 && LTV <= 95) {
        fhaData.mip = 100;
        fhaData.LTV = '<= 95';
        fhaData.loanAmount = '> 625,500';
        fhaData.term = '> 15';

      }
      else if (term > 15 && loanAmount > 625500 && LTV > 95) {
        fhaData.mip = 105;
        fhaData.LTV = '> 95';
        fhaData.loanAmount = '> 625,500';
        fhaData.term = '> 15';

      }
      else if (term <= 15 && loanAmount <= 625500 && LTV <= 90) {
        fhaData.mip = 45;
        fhaData.LTV = '<= 90';
        fhaData.loanAmount = '<= 625,500';
        fhaData.term = '<= 15';
      }

      else if (term <= 15 && loanAmount <= 625500 && LTV > 90) {
        fhaData.mip = 70;
        fhaData.LTV = '> 90';
        fhaData.loanAmount = '<= 625,500';
        fhaData.term = '<= 15';
      }
      else if (term <= 15 && loanAmount > 625500 && LTV <= 78) {
        fhaData.mip = 45;
        fhaData.LTV = '<= 78';
        fhaData.loanAmount = '> 625,500';
        fhaData.term = '<= 15';
      }
      else if (term <= 15 && loanAmount > 625500 && LTV >= 78.01 && LTV <= 90) {
        fhaData.mip = 70;
        fhaData.LTV = '>= 78.01 && LTV <= 90';
        fhaData.loanAmount = '> 625,500';
        fhaData.term = '<= 15';
      }

      else if (term <= 15 && loanAmount > 625500 && LTV > 90) {
        fhaData.mip = 95;
        fhaData.LTV = '> 90';
        fhaData.loanAmount = '> 625,500';
        fhaData.term = '<= 15';
      }
      mortgageInsurance = loanAmount * fhaData.mip / (10000 * 12);
      fhaData.mortgageInsurance = mortgageInsurance
      fhaData.mortgageInsurance = mortgageInsurance && !isNaN(mortgageInsurance) && isFinite(mortgageInsurance) ? mortgageInsurance.toFixed(2) : 0;
      return fhaData;
    } catch (error) {
      console.log(error);

    }
    return 0;
  }

  beforeUnloadHandler(e) {
    const dirtyCollections = document.documentElement.querySelectorAll('.dirty-field') || [];
    const dirtyArr = Array.from(dirtyCollections);
    const dirtyIsActive = dirtyArr.some(d => d.clientHeight && d.clientWidth);
    // Cancel the event
    if (dirtyIsActive) {
      // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
    }
  }

  mounted() {
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
  }
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
  }

}
