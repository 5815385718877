











//Importing Dependecies
import { Component, Vue, Prop } from "vue-property-decorator";
import VueSignaturePad from "vue-signature-pad";
import { BASE_API_URL } from '@/config';
import Axios from 'axios';


// Using for Signature pad
Vue.use(VueSignaturePad);

// Export class with component
@Component({ components: {} })
export default class DigitalSignature extends Vue {
    private signEmptyError = null;
    private sign = null;

    extractSignatureData() {
        let obj = this.$refs.signaturePad["saveSignature"]();

        if (obj.isEmpty) {
            this.signEmptyError = true;
            return null;
        }

        return obj.data;
    }

    private clearSignaturePad() {
        try {
            this.$refs.signaturePad['clearSignature']();
        } catch (error) {
            console.log(error);
        }
    }
}
