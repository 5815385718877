























import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class SaveLosButton extends Vue {
  @Prop({ default: false }) public disabled: boolean;
  @Prop({ default: false }) public loading: boolean;

  public get classes() {
    return {
      'save-los-button': true,
      'save-los-button--disabled': this.disabled,
      'radius__border-5': this.disabled,
    }
  }

  public handlePageReload() {
    location.reload();
    return false;
  }
}
