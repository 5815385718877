






















































import { Component, Vue, Prop } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";

import Axios from "axios";

@Component({
  components: {
  },
})
export default class SigneeValidation extends Vue {
  @Prop({ required: true }) public borrowerDetails: any;

  private borrowerIndex: Number = 0;
  private borrowerSsn: String = '';

  private get session() {
    return this.$store.state.sessionObject;
  }

  private get loanTxnId() {
    return this.$route.query.id;
  }

  private get isThereACoborrowerOnLoan() {
    return (this.borrowerDetails && this.borrowerDetails.coBorrower);
  }

  private async validateDisclosureSignee() {
    try {
      const response = await Axios.post(
        BASE_API_URL + "disclosure/validateDisclosureSignee",
        {
          loanTxnId: this.loanTxnId,
          borrowerId: this.session.borrowerId,
          ssn: this.borrowerSsn,
          index: this.borrowerIndex,
        },
        {
        }
      );

      this.$emit('onValidated', response.data)

      if (!response.data.isValid) { 
        this.$snotify.error("SSN invalid, please type your last four digits");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    if (this.borrowerDetails.borrower.eDisclosureAgreement) {
      this.borrowerIndex = this.borrowerDetails.borrower.index

      return;
    }

    if (this.borrowerDetails.coBorrower && this.borrowerDetails.coBorrower.eDisclosureAgreement) {
      this.borrowerIndex = this.borrowerDetails.coBorrower.index
    }
  }
}
